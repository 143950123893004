<template>
  <div class="footer">
    Some footer
  </div>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style scoped>
.footer {
  text-align: center;
  color: green;
}
</style>
