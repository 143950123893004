<template>
  <div>
    <h1>First update</h1>
    <h2>{{ num }}</h2>
    <button @click="setOne">Первая кнопка</button>&nbsp;
    <button @click="setTwo">Вторая кнопка</button>&nbsp;
    <button @click="setThree">Третья кнопка</button>&nbsp;
    <button @click="setFour">Четвертая кнопка</button>&nbsp;
    <button @click="setFive">Пятая кнопка</button>
    <p><button @click="addNum">Добавить</button> <button @click="remNum">Вычесть</button></p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      num: 0
    }
  },
  methods: {
    setOne () {
      this.num = 1
    },
    setTwo () {
      this.num = 2
    },
    setThree () {
      this.num = 3
    },
    setFour () {
      this.num = 4
    },
    setFive () {
      this.num = 5
    },
    addNum () {
      this.num++
    },
    remNum () {
      this.num--
    }
  }
}
</script>

<style scoped>
h1 {
  color: red;
}
</style>
