<template>
  <img alt="Vue logo" src="./assets/logo.png">
  <TheBody msg="Welcome to Your Vue.js App"/>
  <TheFooter />
</template>

<script>
import TheBody from './components/TheBody.vue'
import TheFooter from './components/TheFooter.vue'

export default {
  name: 'App',
  components: {
    TheBody,
    TheFooter
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
